/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/display-name */
/* eslint-disable func-names */
/* eslint-disable eqeqeq */
import React from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import SaveInAnotherAccountAlert from '../../Base/Elements/Alerts/SaveInAnotherAccountAlert';
import UserPermissionsAlert from '../../Base/Elements/Alerts/UserPermissionsAlert';
import URLInputBox from './Elements/URLInputBox';
import Dropdown from './Elements/TunnelDropDown';

export default function (props: any) {
  const tunnels = props.tunnels ? props.tunnels : [];
  const authHeader = {
    'retrohook-api-key': props.selectedWorkspace.hash.value,
  };
  const actionShort = props?.workflow.next.type.substring(0, 4);

  return (
    <div className="py-4">
      {props.error == 'SaveInAnotherAccountException' && <SaveInAnotherAccountAlert closeAlert={() => props.clearError()} />}
      {props.error == 'UserPermissionsException' && <UserPermissionsAlert closeAlert={() => props.clearError()} />}
      <div className="px-2 md:mt-0">
        <div className="sm:rounded-md sm:overflow-hidden">
          <div className="dark:bg-gray-800 rounded space-y-6 p-6 mx-6 border-solid border border-cyan-300 bg-transparent">
            <div className="py-2 w-full">
              <div>
                <div className="w-full">
                  {actionShort == 'HTTP' && (
                    <div className="mt-4">
                      <URLInputBox leftInput="->" url="Destination URL" onChange={(event: any) => props.handleNextUrl(event)} value={props.workflow.next.url} />
                    </div>
                  )}
                  {actionShort == 'MLLP' && (
                    <div className="z-auto grid grid-cols-8 gap-4">
                      <div className="col-span-2">
                        <Dropdown tunnels={tunnels} selected={props.workflow.next?.tunnel} selectHandler={props.handleNextTunnel} />
                      </div>
                      <div className="col-span-4 mt-2">
                        <URLInputBox
                          leftInput="->"
                          url="Destination Override"
                          onChange={(event: any) => props.handleNextIP(event)}
                          value={props.workflow.next.ip}
                        />
                      </div>
                      <div className="col-span-1 mt-2">
                        <URLInputBox
                          leftInput=":"
                          url="Port Override"
                          onChange={(event: any) => props.handleNextPort(event)}
                          value={props.workflow.next.port}
                        />
                      </div>
                    </div>
                  )}
                  {actionShort.substring(0, 2) == 'S3' && (
                    <div className="flex mt-4 items-center">
                      <div className="flex-grow space-x-4 flex w-full">
                        <div className="inline">
                          <URLInputBox
                            url="S3 Bucket Name"
                            onChange={(event: any) => {
                              props.handleNextS3BucketName(event);
                            }}
                            value={props.workflow.next.bucket}
                          />
                        </div>
                        <div className="inline">
                          <URLInputBox
                            url="SubFolder (Optional)"
                            onChange={(event: any) => {
                              props.handleNextS3Key(event);
                            }}
                            value={props.workflow.next.key}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {actionShort == 'Pipe' && (
                  <div>
                    <h3 className="text-lg text-gray-200">PipeDream Settings</h3>
                    <div className="relative border border-gray-300 rounded px-2 py-2 my-4 shadow-sm">
                      <label
                        htmlFor="env-info"
                        className="absolute rounded -top-4 left-2 -mt-px inline-block px-1 bg-gray-100 dark:bg-gray-800 text-xs font-medium text-gray-600 dark:text-gray-200"
                      >
                        Client ID
                      </label>
                      <div className="mt-3 flex">
                        <p id="env-info" className="text-xl text-gray-200 flex-1 inline">
                          <input
                            type="text"
                            name="clientId"
                            id="clientId"
                            value={props.workflow.next.clientId}
                            onChange={(event: any) => props.handleNextClientId(event)}
                            className="block w-full bg-transparent focus:ring-cyan-500 focus:border-cyan-500 rounded-md sm:text-sm border-gray-300 dark:border-gray-700"
                          />
                        </p>
                      </div>
                    </div>
                    <div className="relative border border-gray-300 rounded px-2 py-2 my-4 shadow-sm">
                      <label
                        htmlFor="env-info"
                        className="absolute rounded -top-4 left-2 -mt-px inline-block px-1 bg-gray-100 dark:bg-gray-800 text-xs font-medium text-gray-600 dark:text-gray-200"
                      >
                        Client Secret
                      </label>
                      <div className="mt-3 flex">
                        <p id="env-info" className="text-xl text-gray-200 flex-1 inline">
                          <input
                            type="password"
                            name="clientSecret"
                            id="clientSecret"
                            value={props.workflow.next.clientSecret}
                            onChange={(event: any) => props.handleNextClientSecret(event)}
                            className="block w-full bg-transparent focus:ring-cyan-500 focus:border-cyan-500 rounded-md sm:text-sm border-gray-300 dark:border-gray-700"
                          />
                        </p>
                      </div>
                    </div>
                    <div className="relative border border-gray-300 rounded px-2 py-2 my-4 shadow-sm">
                      <label
                        htmlFor="env-info"
                        className="absolute rounded -top-4 left-2 -mt-px inline-block px-1 bg-gray-100 dark:bg-gray-800 text-xs font-medium text-gray-600 dark:text-gray-200"
                      >
                        Org ID
                      </label>
                      <div className="mt-3 flex">
                        <p id="env-info" className="text-xl text-gray-200 flex-1 inline">
                          <input
                            type="text"
                            name="orgId"
                            id="orgId"
                            value={props.workflow.next.orgId}
                            onChange={(event: any) => props.handleNextOrgId(event)}
                            className="block w-full bg-transparent focus:ring-cyan-500 focus:border-cyan-500 rounded-md sm:text-sm border-gray-300 dark:border-gray-700"
                          />
                        </p>
                      </div>
                    </div>
                    <div className="relative border border-gray-300 rounded px-2 py-2 my-4 shadow-sm">
                      <label
                        htmlFor="env-info"
                        className="absolute rounded -top-4 left-2 -mt-px inline-block px-1 bg-gray-100 dark:bg-gray-800 text-xs font-medium text-gray-600 dark:text-gray-200"
                      >
                        Project ID
                      </label>
                      <div className="mt-3 flex">
                        <p id="env-info" className="text-xl text-gray-200 flex-1 inline">
                          <input
                            type="text"
                            name="projectId"
                            id="projectId"
                            value={props.workflow.next.projectId}
                            onChange={(event: any) => props.handleNextProjectId(event)}
                            className="block w-full bg-transparent focus:ring-cyan-500 focus:border-cyan-500 rounded-md sm:text-sm border-gray-300 dark:border-gray-700"
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {props.workflow.trigger.type.split('0') == 'HTTPS' && !props.workflow.SK && (
                <div className="pb-2">
                  <p className="text-xl text-gray-200 px-4 mx-1 py-6 my-4 border-4 border-orange-200 rounded">
                    <ExclamationCircleIcon className="h-12 w-12 inline text-orange-300 rounded" />
                    The workflow must be saved in order to generate the HTTPS entry point.
                  </p>
                </div>
              )}
              {props.workflow.trigger.type.split('0') == 'HTTPS' && (
                <div className="relative border border-cyan-300 col-span-2 rounded px-3 mt-4 shadow-sm">
                  <label
                    htmlFor="payload-info"
                    className="absolute rounded bg-gray-100 -top-4 left-2 -mt-px inline-block px-1  dark:bg-gray-800 text-xs font-medium dark:text-gray-200"
                  >
                    Authorization Header
                  </label>
                  <div className="mt-1 flex overflow-auto">
                    <pre className="text-sm dark:text-cyan-400 text-gray-600 py-2">
                      {JSON.stringify(authHeader, null, 2).substring(2, JSON.stringify(authHeader, null, 2).length - 2)}
                    </pre>
                  </div>
                </div>
              )}
            </div>
            {(actionShort == 'HTTP' || actionShort == 'MLLP') && (
              <div className="relative border border-cyan-300 col-span-2 rounded px-3 mb-4 shadow-sm">
                <label
                  htmlFor="payload-info"
                  className="absolute -top-4 left-2 -mt-px inline-block px-1 dark:bg-gray-800 text-md font-medium text-gray-600 dark:text-gray-200 bg-gray-100 rounded"
                >
                  Sample Body
                </label>
                <div className="mt-1 flex overflow-auto">
                  <pre className="text-sm dark:text-cyan-400 text-gray-600 py-2 text-wrap">{JSON.stringify(props.sampleMap, null, 2)}</pre>
                </div>
              </div>
            )}
            {props?.workflow?.next?.type.split(' ')[1] == 'FHIR' && (
              <div className="relative border border-cyan-300 col-span-2 rounded px-3 mt-4 w-full shadow-sm">
                <label
                  htmlFor="payload-info"
                  className="absolute -top-4 left-2 -mt-px rounded inline-block px-1 dark:bg-gray-800 text-xs font-medium dark:text-gray-200 bg-gray-100"
                >
                  Sample Bundle Resource
                </label>
                <div className="mt-1 flex">
                  <pre className="text-sm dark:text-cyan-400 text-gray-600 p-2">{JSON.stringify(props.fhirSampleMap, null, 2)}</pre>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
