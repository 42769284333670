/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/function-component-definition */
import { useState, useEffect } from 'react';
import { Cog8ToothIcon, TrashIcon, SparklesIcon } from '@heroicons/react/24/outline';
import SaveInAnotherAccountAlert from '../../Base/Elements/Alerts/SaveInAnotherAccountAlert';
import UserPermissionsAlert from '../../Base/Elements/Alerts/UserPermissionsAlert';
import FlowHeading from './Elements/FlowHeading';
import Toggle from './Elements/GridToggle';
import logo from '../../../../images/retrohook.png';

export default function TLayer(props: any) {
  const [fieldMapGrid, setFieldMapGrid] = useState(props.fieldMapGrid);
  const [fhirLoading, setFhirLoading] = useState(props.fhirLoading);
  const [fhirProgress, setFhirProgress] = useState(props.fhirProgress);

  useEffect(() => {
    setFieldMapGrid(props.fieldMapGrid);
    setFhirLoading(props.fhirLoading);
    setFhirProgress(props.fhirProgress);
  }, [props.fieldMapGrid, props.fhirMap, props.fhirLoading, props.fhirProgress]);

  return (
    <div className="py-4">
      {props.error === 'SaveInAnotherAccountException' && <SaveInAnotherAccountAlert closeAlert={() => props.clearError()} />}
      {props.error === 'UserPermissionsException' && <UserPermissionsAlert closeAlert={() => props.clearError()} />}
      {props.workflow.next != 'FHIRR4' && (
        <div className="px-2 md:mt-0">
          <div className="sm:rounded-md sm:overflow-hidden">
            <div className="dark:bg-gray-800 space-y-6 px-2">
              {props.workflow.trigger.type.substring(0, 4) === 'MLLP' && (
                <table className="min-w-full divide-y divide-cyan-400 border-2 border-cyan-400">
                  <thead className="bg-transparent dark:bg-gray-900">
                    <tr>
                      <td colSpan={4} className="">
                        <div className="flex justify-between items-center px-4 py-2">
                          <h2 className="text-md text-gray-600 dark:text-gray-200">Data Map</h2>
                          <div className="flex items-center space-x-4">
                            {(props.workflow.next.type === 'S3 FHIR R4' || props.workflow.next.type === 'HTTPS FHIR R4') && (
                              <div className="inline-flex items-center mx-1 mr-2 text-gray-500 hover:text-gray-200">
                                {fhirLoading ? (
                                  <div className="relative px-2 py-1 border-2 border-pink-500 rounded-md shadow-sm animate-wiggle focus:outline-none cursor-pointer">
                                    <div className="absolute bottom-0 left-0 w-full bg-pink-500/80" style={{ height: `${fhirProgress}%` }} />
                                    <SparklesIcon className="h-6 w-8 inline cursor-pointer text-gray-600 dark:text-gray-200" />
                                  </div>
                                ) : (
                                  <div
                                    className="px-2 py-1 border-2 border-cyan-400 hover:bg-cyan-400 rounded-md shadow-sm bg-transparent focus:outline-none cursor-pointer"
                                    onClick={() => props.fetchFHIRR4Map()}
                                  >
                                    <SparklesIcon className="h-6 w-8 inline cursor-pointer text-cyan-400 dark:text-gray-200" />
                                  </div>
                                )}
                              </div>
                            )}
                            {props.workflow.trigger.type.split(' ')[1] === 'HL7v2' && (
                              <div className="flex items-center space-x-2 py-1 text-sm text-gray-600 dark:text-gray-100 dark:bg-gray-800">
                                <Toggle enabled={props.sampleFilter} setEnabled={() => props.enableSampleFilter()} />
                                <span>View All</span>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="border-t border-t-cyan-400" />
                      </td>
                    </tr>
                    <tr>
                      <th scope="col" className="px-4 py-2 text-left text-s font-medium text-cyan-300 uppercase tracking-wider">
                        Field
                      </th>
                      <th scope="col" className="px-2 py-2 text-left text-s font-medium text-cyan-300 uppercase tracking-wider">
                        Specification
                      </th>
                      <th scope="col" className="px-2 py-2 text-left text-m font-medium text-gray-500 uppercase tracking-wider">
                        SAMPLE
                      </th>
                      {props.workflow.next.type == 'S3 FHIR R4' ? (
                        <th scope="col" className="px-2 py-2 text-left text-m font-medium text-cyan-300 uppercase tracking-wider">
                          TARGET PATH
                        </th>
                      ) : (
                        <th scope="col" className="px-2 py-2 text-left text-m font-medium text-cyan-300 uppercase tracking-wider">
                          TARGET KEY
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {fieldMapGrid.map((map: any, idx: number) => (
                      <tr
                        key={`${map[1].field}-${map[2].sample}${idx}`}
                        className={idx % 2 === 0 ? 'bg-gray-100 dark:bg-gray-700 hover:bg-cyan-500' : 'dark:bg-gray-800 hover:bg-cyan-500'}
                      >
                        <td className="pl-4 pr-2 pt-1 whitespace-nowrap text-sm dark:text-gray-200">{map[1].field}</td>
                        <td className="px-2 pb-2 pt-1 whitespace-wrap text-xs truncate font-light dark:text-gray-200">{map[0].definition}</td>
                        <td className="px-2 pb-2 pt-1 whitespace-wrap text-wrap truncate text-sm dark:text-gray-200">
                          {map[2].sample.substring(0, 75)}
                          {map[2].sample.length > 75 ? `...` : ``}
                        </td>
                        <td className="px-2 py-1 whitespace-wrap flex w-full text-sm dark:text-gray-100">
                          {(props.workflow.trigger.type.substring(0, 4) === 'MLLP' || props.workflow.trigger.type.substring(0, 4) === 'HTTP') && (
                            <input
                              className="dark:bg-gray-900 flex focus:ring-cyan-300 focus:border-cyan-300 w-full sm:text-sm border-cyan-300 border-2 rounded"
                              type="text"
                              name="target"
                              id={map[1].field}
                              defaultValue={map[3].target ? map[3].target : null}
                              onChange={(event) => {
                                props.updateFieldMap(event);
                              }}
                            />
                          )}
                          <div className="pl-2 mt-1 inline rounded-md" onClick={() => props.toggleLookUpModal(map)}>
                            <Cog8ToothIcon className="h-8 w-8 inline cursor-pointer text-gray-500 hover:text-cyan-400" />
                          </div>
                          {!props.sampleFilter && (
                            <div
                              className="pl-0 ml-1 pl-1 pt-1 inline rounded-md"
                              onClick={() => {
                                props.removeFieldMapRow(map);
                              }}
                            >
                              <TrashIcon className="h-8 w-8 inline cursor-pointer text-gray-500 rounded hover:text-pink-400" />
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {props.workflow.next.type.substring(0, 4) === 'MLLP' && (
                <table className="min-w-full divide-y divide-cyan-400 border-2 border-cyan-400">
                  <thead className="bg-transparent dark:bg-gray-900">
                    <tr>
                      {props.workflow.next.type === 'S3 FHIR R4' ? (
                        <th scope="col" className="px-2 py-2 text-left text-m font-medium text-cyan-300 uppercase tracking-wider">
                          TARGET PATH
                        </th>
                      ) : (
                        <th scope="col" className="px-2 py-2 text-left text-m font-medium text-cyan-300 uppercase tracking-wider">
                          TARGET KEY
                        </th>
                      )}

                      <th scope="col" className="px-2 py-2 text-left text-s font-medium text-cyan-300 uppercase tracking-wider">
                        Specification
                      </th>
                      <th scope="col" className="px-2 py-2 text-left text-m font-medium text-gray-500 uppercase tracking-wider">
                        SAMPLE
                      </th>
                      <th scope="col" className="px-4 py-2 text-left text-s font-medium text-cyan-300 uppercase tracking-wider">
                        Field
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {fieldMapGrid.map((map: any, idx: number) => (
                      <tr
                        key={`${map[1].field}-${map[2].sample}${idx}`}
                        className={idx % 2 === 0 ? 'bg-gray-100 dark:bg-gray-700 hover:bg-cyan-500' : 'dark:bg-gray-800 hover:bg-cyan-500'}
                      >
                        {(props.workflow.trigger.type.substring(0, 4) === 'MLLP' || props.workflow.trigger.type.substring(0, 4) === 'HTTP') && (
                          <input
                            className="mx-4 my-2 text-gray-600 dark:text-gray-200 dark:bg-gray-900 flex focus:ring-cyan-300 focus:border-cyan-300 sm:text-sm border-cyan-300 border-2 rounded"
                            type="text"
                            name="target"
                            id={map[1].field}
                            defaultValue={map[3].target ? map[3].target : null}
                            onChange={(event) => {
                              props.updateFieldMap(event);
                            }}
                          />
                        )}

                        <td className="pb-2 pt-3 whitespace-wrap text-xs truncate font-light text-gray-600 dark:text-gray-200">{map[0].definition}</td>
                        <td className="px-2 pb-2 pt-3 whitespace-wrap text-wrap truncate text-sm text-gray-600 dark:text-gray-200">
                          {map[2].sample.substring(0, 75)}
                          {map[2].sample.length > 75 ? `...` : ``}
                        </td>
                        <td className="px-2 pb-2 pt-3 whitespace-wrap flex text-sm text-gray-600 dark:text-gray-200">
                          <td className="pt-3 px-4 whitespace-nowrap text-sm text-gray-600 dark:text-gray-200">{map[1].field}</td>
                          <div className="px-0 m-1 flex rounded-md" onClick={() => props.toggleLookUpModal(map)}>
                            <Cog8ToothIcon className="h-8 w-8 cursor-pointer text-gray-500 hover:text-cyan-400" />
                          </div>
                          {!props.sampleFilter && (
                            <div
                              className="pl-0 m-1 inline rounded-md"
                              onClick={() => {
                                props.removeFieldMapRow(map);
                              }}
                            >
                              <TrashIcon className="h-8 w-8 cursor-pointer text-gray-500 rounded hover:text-pink-400" />
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      )}
      {props.loading == true && !props.workflow.fields.length && fhirLoading == false && (
        <div className="px-2 md:mt-0 md:col-span-7">
          <div className="sm:rounded-md sm:overflow-hidden">
            <div className="bg-gray-800 rounded-md space-y-6 p-6 mx-6 border-solid border shadow border-cyan-300">
              <div className="inline-flex w-full">
                <FlowHeading title="Workflow Mappings & Transformations" />
                <div className="inline-flex items-center px-2 py-1 mx-1 mr-2 border border-cyan-400 text-gray-200 rounded-md shadow-sm text-xs bg-gray-800 focus:outline-none">
                  <span className="text-sm">Selected&nbsp;&nbsp;</span>
                  <Toggle enabled={props.sampleFilter} setEnabled={() => props.enableSampleFilter()} />
                  <span className="text-sm">&nbsp;&nbsp;All</span>
                </div>
              </div>
              <div className="border border-top border-cyan-300 mx-2" />
              <div className="items-center animate-pulse py-48 sm:py-24 md:py-32 lg:py-48">
                <img className="h-24 mx-auto w-auto" src={logo} alt="Logo" />
                <h2 className="mt-0 text-center text-2xl font-bold text-gray-200">
                  Retrohook
                  <span className="inline align-middle text-xs tracking-tight text-cyan-300 pl-1" />
                </h2>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
