import React from "react";

export default function ActionButton(props: any) {
  const { title, clickHandler } = props;
  return (
    <div className="pt-1 mr-2">
      <button
        type="button"
        onClick={() => clickHandler()}
        className="inline whitespace-nowrap w-full items-center text-xs text-gray-100 focus:outline-none  hover:text-purple-400"
      >
        {title}
      </button>
    </div>
  );
}
