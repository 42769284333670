/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-shadow */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import Hl7Standard from 'hl7-standard';
import useFetch from 'use-http';
import { flattenSampleData, flattenSampleDataNoAmpersand, buildPayload, transformPayload, createFHIRBundle } from '@1putthealth/retrohook-utilities';
import uuid from 'react-uuid';
import config from '../../config';

interface WorkflowProps {
  selectedEnvironment: any;
  selectedWorkspace: any;
  user: any;
  admin: boolean;
  notify: (notification: { message: string }) => void;
}

interface Workflow {
  SK: string;
  PK: string;
  flowName: string;
  active: boolean;
  flowDescription: string;
  icon: string;
  sampleMessage: string;
  trigger: any;
  next: any;
  fields: Record<string, any>;
  lookups: any[];
  events: any[];
  environment: any;
  workspace: any;
  owner: any;
  workspaceId: string;
  environmentId: string;
}

const hl7v2EventTofhirr4MethodMap = [
  { id: 1, hl7v2: 'A01', label: 'A01 -> Create', method: 'CREATE' },
  { id: 2, hl7v2: 'A08', label: 'A08 -> Update', method: 'UPDATE' },
  { id: 3, hl7v2: 'A02', label: 'A02 -> Update', method: 'UPDATE' },
  { id: 4, hl7v2: 'A04', label: 'A04 -> Create', method: 'CREATE' },
  { id: 5, hl7v2: 'A05', label: 'A05 -> Create', method: 'CREATE' },
  { id: 6, hl7v2: 'A11', label: 'A11 -> Update', method: 'UPDATE' },
  { id: 7, hl7v2: 'A12', label: 'A12 -> Update', method: 'UPDATE' },
  { id: 8, hl7v2: 'A13', label: 'A13 -> Update', method: 'UPDATE' },
  { id: 9, hl7v2: 'A31', label: 'A31 -> Update', method: 'UPDATE' },
  { id: 10, hl7v2: 'A40', label: 'A40 -> Update', method: 'UPDATE' },
  { id: 11, hl7v2: 'A03', label: 'A03 -> Update', method: 'UPDATE' },
];

const useWorkflows = (props: WorkflowProps) => {
  const { post, response, loading } = useFetch(config.url);
  const placeholder: Workflow = {
    SK: '',
    PK: '',
    flowName: '',
    flowDescription: '',
    active: false,
    icon: '',
    sampleMessage: '',
    trigger: {
      type: 'MLLP HL7v2',
      label: 'HL7v2 Message Events (TCP/MLLP)',
      formula: '',
      key: '',
      custom: {},
      host: '',
      port: 22,
      username: '',
      password: '',
      path: '',
    },
    next: { type: 'HTTPS JSON', label: 'JSON POST Request (HTTPS)', url: '', ip: '', port: '', action: '', key: '', bucket: '', method: '' },
    fields: {},
    lookups: [],
    events: [],
    environment: props?.selectedEnvironment,
    workspace: props?.selectedWorkspace,
    owner: props?.user,
    workspaceId: props?.selectedWorkspace?.SK,
    environmentId: props?.selectedEnvironment?.SK,
  };
  const [ux, setUx] = useState('list');
  const [error, setError] = useState('');
  const [copied, setCopied] = useState(false);
  const [pending, setPending] = useState(false);
  const [lookUpModal, setLookUpModal] = useState(false);
  const [segmentFields, setSegmentFields] = useState<any | null>(null);
  const [selectedField, setSelectedField] = useState<any[]>([{ field: null }]);
  const [selectedFieldMap, setSelectedFieldMap] = useState<any[]>([]);
  const [selectedDefaultValue, setSelectedDefaultValue] = useState('');
  const [triggerSample, setTriggerSample] = useState('');
  const [triggerMap, setTriggerMap] = useState(false);
  const [showEnvironmentDropdown, setShowEnvironmentDropdown] = useState(false);
  const [trigger, setTrigger] = useState<any | null>(null);
  const [next, setNext] = useState<any | null>(null);
  const [sampleMap, setSampleMap] = useState<any>({});
  const [sampleMessageInput, setSampleMessageInput] = useState(true);
  const [targets, setTargets] = useState<any | null>(null);
  const [fieldMapGrid, setFieldMapGrid] = useState<any[]>([]);
  const [eventTriggerGrid, setEventTriggerGrid] = useState<any[]>([]);
  const [sampleFilter, setSampleFilter] = useState(true);
  const [definitions, setDefinitions] = useState<any[]>([]);
  const [workflow, setWorkflow] = useState<Workflow>(placeholder);
  const [currentLookups, setCurrentLookups] = useState<Record<string, any>>({});
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showMessageIDModal, setShowMessageIDModal] = useState(false);
  const [messageEvent] = useState('');
  const [fhirMap, setFhirMap] = useState<any>();
  const [fhirLoading, setFhirLoading] = useState(false);
  const [fhirSampleMap, setFHIRSampleMap] = useState<any>({});
  const [showTemplatesModal, setShowTemplatesModal] = useState(false);
  const [templates, setTemplates] = useState<any[]>([]);
  const [tunnels, setTunnels] = useState<any[]>([]);

  const hydrateMappingGrids = useCallback(async () => {
    if (definitions.length > 0 && workflow.sampleMessage) {
      const newFieldMapGrid: any = [];
      const newTriggerMapGrid: any = [];
      let triggerSampleData: any = [];
      const addedTriggerKeys = new Set();
      const sampleHL7 = new Hl7Standard(workflow.sampleMessage);
      sampleHL7.transform(async (err: any) => {
        if (err) {
          console.log(err);
        }
      });
      const segments = sampleHL7.getSegments();
      const sampleData: any[] = [];
      for (let i = 0; i < segments.length; i++) {
        const segment = Object.entries(segments[i].data);
        segment.forEach((entry) => {
          const [key, value] = entry;
          if (typeof value === 'object') {
            const subfieldEntries = Object.entries(value);
            subfieldEntries.forEach(([subfieldKey, subfieldValue]) => {
              const newSubfieldKey = subfieldKey;
              if (typeof subfieldValue === 'object') {
                const flattenedSubfieldData = flattenSampleData(subfieldValue, newSubfieldKey);
                sampleData.push(...flattenedSubfieldData);
              } else {
                const flattenedSubfieldData = flattenSampleDataNoAmpersand(value, newSubfieldKey);
                sampleData.push(...flattenedSubfieldData);
              }
            });
          } else sampleData.push([key, value]);
        });
      }
      const fields = Object.entries(workflow.fields);
      const triggerObject = workflow.trigger.custom ? workflow.trigger.custom : {};
      sampleData.map((entry: any) => {
        let specificationValue: any = null;
        const [entryKey, entryValue] = entry;
        const specificationMap = definitions.filter((Map: any) => {
          if (Map[0] === entryKey) {
            return Map;
          }
          return null;
        });
        if (specificationMap[0]) {
          specificationValue = specificationMap[0][1];
        }
        fields.map((field) => {
          const [fieldKey, fieldValue] = field;
          const duplicateFields = newFieldMapGrid.filter((row: any) => row[1].field === fieldKey);
          if (entryKey === fieldKey && fieldValue && duplicateFields.length === 0) {
            newFieldMapGrid.push([{ definition: specificationValue || 'CUSTOM' }, { field: entryKey }, { sample: entryValue || '' }, { target: fieldValue }]);
          }
          return field;
        });
        for (let index = 0; index < Object.keys(triggerObject).length; index++) {
          const filter = Object.entries(triggerObject)[index];
          if (
            !addedTriggerKeys.has(entryKey) &&
            entryKey === filter[0] &&
            props.selectedWorkspace.hash.location !== entryKey &&
            props.selectedEnvironment.name.substring(3, 6) !== 'Dev'
          ) {
            newTriggerMapGrid.push([{ definition: specificationValue || 'CUSTOM' }, { field: entryKey }, { sample: entryValue || '' }, { filter: filter[1] }]);
            addedTriggerKeys.add(entryKey);
          }
          if (!addedTriggerKeys.has(entryKey) && entryKey === filter[0]) {
            newTriggerMapGrid.push([{ definition: specificationValue || 'CUSTOM' }, { field: entryKey }, { sample: entryValue || '' }, { filter: filter[1] }]);
            addedTriggerKeys.add(entryKey);
          }
        }
        const duplicateTriggerFields = newTriggerMapGrid.filter((row: any) => row[1].field === entryKey);
        const duplicateFields = newFieldMapGrid.filter((row: any) => row[1].field === entryKey);

        if (props.selectedWorkspace && props.selectedWorkspace.hash.location === entryKey && props.selectedEnvironment.name.substring(3, 6) !== 'Dev') {
          triggerSampleData = entryValue || '';
        }
        if (duplicateTriggerFields.length < 1 && sampleFilter) {
          if (
            !addedTriggerKeys.has(entryKey) &&
            ((!specificationValue &&
              entryValue.length > 0 &&
              props.selectedEnvironment.name.substring(3, 6) !== 'Dev' &&
              props.selectedWorkspace.hash.location !== entryKey) ||
              props.selectedWorkspace.hash.location == entryKey ||
              (specificationValue && props.selectedEnvironment.name.substring(3, 6) !== 'Dev' && props.selectedWorkspace.hash.location !== entryKey) ||
              props.selectedWorkspace.hash.location !== entryKey)
          ) {
            newTriggerMapGrid.push([{ definition: specificationValue || 'CUSTOM' }, { field: entryKey }, { sample: entryValue || '' }, { filter: '' }]);
            addedTriggerKeys.add(entryKey);
          }
        }
        if (duplicateFields.length < 1 && sampleFilter) {
          if ((!specificationValue && entryValue.length > 0) || specificationValue) {
            newFieldMapGrid.push([{ definition: specificationValue || 'CUSTOM' }, { field: entryKey }, { sample: entryValue || '' }, { target: '' }]);
          }
        }
      });
      const messageEventFromMessage = sampleHL7.get('MSH.9.2');
      let mappedEvent;
      if (workflow.next.type === 'S3 FHIR R4' || workflow.next.type === 'HTTPS FHIR R4') {
        mappedEvent = hl7v2EventTofhirr4MethodMap.filter((event) => event.hl7v2 === messageEventFromMessage);
        updateTriggerMethod(mappedEvent[0]);
      }
      setTriggerSample(triggerSampleData);
      setEventTriggerGrid(newTriggerMapGrid);
      setFieldMapGrid(newFieldMapGrid);
    }
  }, [
    definitions,
    workflow.sampleMessage,
    sampleFilter,
    workflow.fields,
    workflow?.trigger?.custom,
    workflow?.next?.type,
    props.selectedWorkspace,
    props.selectedEnvironment,
  ]);

  const bootstrapDefinitions = useCallback(async () => {
    const PK = props.user?.account?.PK;
    if (!PK) return;
    const definitions = await post(`workflow/get-definitions/${PK}`);
    if (response.ok && definitions) {
      const defs: any[] = Object.entries(definitions.definitions[0].definition);
      setDefinitions(defs);
    }
  }, [post, props.user?.account?.PK, response.ok]);

  const fetchTemplates = useCallback(async () => {
    if (!props.selectedWorkspace || !props.selectedEnvironment) return;
    const body = {
      selectedWorkspace: props.selectedWorkspace,
      selectedEnvironment: props.selectedEnvironment,
    };
    const request = await post(`workflow/list-templates/`, body);
    if (response.ok && request) {
      setTemplates(request.templates);
    }
  }, [post, props.selectedWorkspace, props.selectedEnvironment, response.ok]);

  const getTunnels = useCallback(async () => {
    const body = { selectedWorkspace: props.selectedWorkspace, selectedEnvironment: props.selectedEnvironment };
    const tunnels: any = await post(`ipsec/list/${props.user.sub}`, body);
    if (response.ok && tunnels.length > 0) {
      tunnels.map(async (element: any) => {
        if (!element.showDownloadTunnelConfig || element.showDownloadTunnelConfig === 'undefined') {
          element.showDownloadTunnelConfig = false;
          element.showPsk = false;
        }
      });
      setTunnels(tunnels ? tunnels : []);
    } else {
      setTunnels([]);
    }
  }, [post, props.selectedWorkspace, props.selectedEnvironment, props.user.sub, response.ok]);

  const validatePayload = useCallback(async () => {
    if (workflow.sampleMessage) {
      const sample = new Hl7Standard(workflow.sampleMessage);
      sample.transform(async (err: any) => {
        if (err) {
          console.log(err);
        }
      });
      if (workflow.lookups.length > 0) {
        const transformed = await transformPayload(sample, workflow.lookups);
        const hl7 = new Hl7Standard(transformed);
        hl7.transform((err: any) => {
          if (err) throw err;
        });
        const payload = await buildPayload(workflow.fields, hl7);
        setSampleMap(payload);
      } else {
        const payload = await buildPayload(workflow.fields, sample);
        setSampleMap(payload);
      }
    }
  }, [workflow.sampleMessage, workflow.lookups, workflow.fields]);

  const updateFieldMap = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (workflow.trigger.type.substring(0, 4) === 'MLLP') {
      if (event.target.value.length < 1) {
        removeFieldMapRow(workflow.fields[event.target.id]);
      } else {
        workflow.fields[event.target.id] = event.target.value;
      }
    }
    if (workflow.trigger.type.substring(0, 4) === 'HTTP') {
      if (event.target.length < 1) {
        removeFieldMapRow(workflow.fields[event.key]);
      } else {
        workflow.fields[event.key] = event.target;
      }
    }
    setWorkflow(workflow);
    hydrateMappingGrids();
  };

  const canCreate = () => {
    const hasUpdateWorkflowInSelectedWorkspace = props.selectedWorkspace.accounts.filter((account: any) => {
      if (account.PK && account.permissions.workflows.create) return account;
      return null;
    });
    const isWorkspaceOwner = props.selectedWorkspace.email === props.user.account.email;
    if (props.admin === true || hasUpdateWorkflowInSelectedWorkspace.length || isWorkspaceOwner) {
      return true;
    }
    return false;
  };

  const canUpdate = () => {
    const hasUpdateWorkflowInSelectedWorkspace = props.selectedWorkspace.accounts.filter((account: any) => {
      if (account.PK && account.permissions.workflows.update) return account;
      return null;
    });
    const isWorkspaceOwner = props.selectedWorkspace.email === props.user.account.email;
    if (props.admin === true || hasUpdateWorkflowInSelectedWorkspace.length || isWorkspaceOwner) {
      return true;
    }
    return false;
  };

  const clearWorkflow = () => {
    setWorkflow(placeholder);
  };

  const loadWorkflow = (loadedFlow: Workflow) => {
    try {
      setPending(true);
      fetchTemplates();
      clearWorkflow();
      setWorkflow(loadedFlow);
      if (loadedFlow.sampleMessage && loadedFlow.fields) {
        if (loadedFlow.next.type === 'S3 FHIR R4' || (loadedFlow.next.type === 'HTTPS FHIR R4' && loadedFlow.fields)) {
          const map = createFHIRBundle(loadedFlow.fields, loadedFlow.sampleMessage);
          setFHIRSampleMap(map);
        }
        validatePayload();
      }
      setUx('adapters');
      setPending(false);
    } catch (error) {
      console.error('Error loading workflow', error);
    }
  };

  const saveWorkflow = async () => {
    setPending(true);
    if (
      props.selectedEnvironment.name.substring(3, 6) != 'Dev' &&
      workflow.trigger.type.substring(0, 4) == 'MLLP' &&
      triggerSample != props.selectedWorkspace.hash.value
    ) {
      setShowMessageIDModal(true);
    }
    await saveFlow();
    setPending(false);
  };

  const saveTemplate = async () => {
    const { selectedEnvironment } = props;
    const PK = workflow.owner.PK;
    setPending(true);
    if (!workflow.flowName) {
      workflow.flowName = 'My Template';
    }
    const template = {
      PK,
      SK: 'WFTEMPLATEID#' + uuid(),
      icon: workflow.icon ? workflow.icon : 'BoltIcon',
      flowName: workflow.flowName,
      flowDescription: workflow.flowDescription,
      fields: workflow.fields,
      next: workflow.next,
      trigger: workflow.trigger,
      lookups: workflow.lookups,
      Entity: 'WFTEMPLATE',
      deleted: 'false',
    };
    const body = { template, selectedEnvironment };
    const newTemplate = await post(`workflow/save-template/${PK}`, body);
    if (response.ok && newTemplate) {
      props.notify({ message: `Template Saved` });
    }
    setPending(false);
    fetchTemplates();
  };

  const saveFlow = async () => {
    const { selectedEnvironment, selectedWorkspace } = props;
    if (workflow.PK.length) {
      if (selectedWorkspace.SK !== workflow.workspaceId && selectedEnvironment.SK === workflow.environmentId) {
        setPending(false);
      }
    }
    if (!workflow.flowName) {
      workflow.flowName = 'My Workflow';
    }
    if (selectedEnvironment.name.substring(3, 6) != 'Dev') {
      workflow.trigger.custom[selectedWorkspace.hash.location] = selectedWorkspace.hash.value;
    } else {
      delete workflow.trigger.custom[selectedWorkspace.hash.location];
    }
    if ((workflow.PK && canUpdate()) || (!workflow.PK && canCreate())) {
      if (!workflow.SK) workflow.SK = 'WORKFLOWID#' + uuid();
      const body = { workflow, environment: selectedEnvironment, workspace: selectedWorkspace };
      const newFlow = await post(`workflow/save-workflow/${props.user.sub}`, body);
      if (response.ok && newFlow) {
        loadWorkflow(newFlow.wf);
        setPending(false);
        setLookUpModal(false);
        props.notify({ message: `Workflow Saved` });
      }
    }
  };

  const isStarted = () => {
    if (workflow?.flowName != placeholder.flowName || workflow?.sampleMessage != placeholder.sampleMessage) {
      return false;
    }
    return false;
  };

  const handleFlowNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updated = { ...workflow, flowName: event.target.value };
    setWorkflow(updated);
  };

  const handleFlowDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updated = { ...workflow, flowDescription: event.target.value };
    setWorkflow(updated);
  };

  const handleSampleMessageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    workflow.sampleMessage = event.target.value;
    const updated = { ...workflow, sampleMessage: event.target.value };
    setWorkflow(updated);
    if (event.target.id === 'sample-message-hl7') {
      await hydrateMappingGrids();
    }
    if (workflow.sampleMessage && event.target.id === 'sample-message-ccda') {
      const body = { ccda: event.target.value, lookups: workflow.lookups };
      const jsonCCDA = await post(`workflow/ccda-to-json/${props.user.sub}`, body);
      if (response.ok && jsonCCDA) console.dir(jsonCCDA);
    }
    if (workflow.sampleMessage && event.target.id === 'sample-message-json') {
      const body = { json: event.target.value, lookups: workflow.lookups };
      const jsonCCDA = await post(`workflow/json-to-ccda/${props.user.sub}`, body);
      if (response.ok && jsonCCDA) {
        console.dir(jsonCCDA);
      }
    }
  };

  const handleNextS3BucketName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updated = {
      ...workflow,
      next: { ...workflow.next, bucket: event.target.value },
    };
    setWorkflow(updated);
  };

  const handleNextS3Key = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updated = {
      ...workflow,
      next: { ...workflow.next, key: event.target.value },
    };
    setWorkflow(updated);
  };

  const handleNextTunnel = (tunnel: any) => {
    const updated = {
      ...workflow,
      next: { ...workflow.next, tunnel },
    };
    setWorkflow(updated);
  };

  const createS3Bucket = async () => {
    const bucketName = workflow.next.bucket;
    const { selectedEnvironment, selectedWorkspace } = props;
    const body = {
      environment: selectedEnvironment,
      workspace: selectedWorkspace,
      account: props.user.account,
      bucketName,
    };
    const bucket = await post(`environment/create-s3-bucket/${props.user.sub}`, body);
    if (bucket && response.ok) {
      setPending(false);
    }
  };

  const handleNextUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updated = {
      ...workflow,
      next: { ...workflow.next, url: event.target.value },
    };
    setWorkflow(updated);
  };

  const handleNextIP = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updated = {
      ...workflow,
      next: { ...workflow.next, ip: event.target.value },
    };
    setWorkflow(updated);
  };

  const handleNextPort = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updated = {
      ...workflow,
      next: { ...workflow.next, port: event.target.value },
    };
    setWorkflow(updated);
  };

  const updateFieldMapKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    for (let index = 0; index < workflow.lookups.length; index++) {
      const lookup = workflow.lookups[index];
      if (lookup.field && lookup.field === selectedField[1].field) {
        const maps = lookup.map;
        for (let index2 = 0; index2 < maps.length; index2++) {
          const map = maps[index2];
          const id = event.target.id.split('-')[2] + 1;
          if (map.id === id) {
            map.key = event.target.value;
          }
        }
      }
    }
    setWorkflow(workflow);
    validatePayload();
  };

  const updateFieldMapValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    for (let index = 0; index < workflow.lookups.length; index++) {
      const lookup = workflow.lookups[index];
      if (lookup.field === selectedField[1].field) {
        const maps = lookup.map;
        for (let index2 = 0; index2 < maps.length; index2++) {
          const map = maps[index2];
          const id = event.target.id.split('-')[2] + 1;
          if (map.id === id) {
            if (event.target.value.length > 0) {
              map.value = event.target.value;
            } else {
              removeRow(index2);
            }
          }
        }
      }
    }
    setWorkflow(workflow);
    validatePayload();
  };

  const handleLookupDefaultValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    for (let index = 0; index < workflow.lookups.length; index++) {
      const lookup = workflow.lookups[index];
      if (lookup.field === selectedField[1].field) {
        lookup.default = event.target.value;
      }
    }
    setWorkflow(workflow);
    setSelectedDefaultValue(event.target.value);
  };

  const toggleLookUpModal = (fieldMap?: any) => {
    if (fieldMap) {
      let found = false;
      const { lookups } = workflow;
      if (lookups?.length > 0) {
        for (let a = 0; a < lookups.length; a++) {
          const lookup = lookups[a];
          if (lookup.field === fieldMap[1].field) {
            found = true;
            if (!lookup.default) {
              setWorkflow(workflow);
            }
            setSelectedDefaultValue(lookup.default);
            setSelectedField(fieldMap);
            setSelectedFieldMap(lookup);
          }
        }
        if (found === false) {
          const lookupParams = {
            field: fieldMap[1].field,
            map: [{ id: 1, key: '', value: '' }],
            default: '',
          };
          workflow.lookups.push(lookupParams);
          for (let a = 0; a < workflow.lookups.length; a++) {
            const lookup = workflow.lookups[a];
            if (lookup.field === fieldMap[1].field) {
              found = true;
              if (!lookup.default) {
                setWorkflow(workflow);
              }
              setSelectedDefaultValue(lookup.default);
              setSelectedField(fieldMap);
              setSelectedFieldMap(lookup);
            }
          }
        }
        setLookUpModal(true);
      } else {
        workflow.lookups = [
          {
            default: '',
            field: fieldMap[1].field,
            map: [
              {
                id: 1,
                key: '',
                value: '',
              },
            ],
          },
        ];
        const selectedFM = workflow.lookups[0];
        setSelectedDefaultValue('');
        setSelectedField(fieldMap);
        setSelectedFieldMap(selectedFM);
        setLookUpModal(true);
        setWorkflow(workflow);
      }
    } else {
      setLookUpModal(false);
    }
  };

  const addRowToLookupsHandler = () => {
    workflow.lookups.map((lookup) => {
      if (lookup.field === selectedField[1].field) {
        lookup.map.push({ id: lookup.map.length + 1, key: '', value: '' });
      }
      return lookup;
    });
    setWorkflow(workflow);
    setCurrentLookups(workflow.lookups);
    toggleLookUpModal(selectedField);
  };

  const removeRow = (id: number) => {
    workflow.lookups.map((lookup) => {
      if (lookup.field === selectedField[1].field) {
        lookup.map.splice(lookup.map.indexOf(lookup.map[id]), 1);
      }
      return lookup;
    });
    setWorkflow(workflow);
  };

  const removeFieldMapRow = (rowArray: any) => {
    if (rowArray[1]?.field) {
      const row = {
        definition: rowArray[0].definition,
        field: rowArray[1]?.field,
        sample: rowArray[2].sample,
        target: rowArray[3].target,
      };
      delete workflow.fields[row.field];
    }
    setWorkflow(workflow);
    hydrateMappingGrids();
  };

  const updateTrigger = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 0) {
      workflow.trigger.custom[event.target.id] = event.target.value;
      const triggerArray = Object.entries(workflow.trigger.custom);
      workflow.trigger.key = triggerArray
        .map((row) => {
          return row[1];
        })
        .join('.');
      workflow.trigger.formula = triggerArray
        .map((row) => {
          return row[0];
        })
        .join('-');
      setWorkflow(workflow);
    } else {
      delete workflow.trigger.custom[event.target.id];
      const triggerArray = Object.entries(workflow.trigger.custom);
      workflow.trigger.key = triggerArray
        .map((row) => {
          return row[1];
        })
        .join('.');
      workflow.trigger.formula = triggerArray
        .map((row) => {
          return row[0];
        })
        .join('-');
      setWorkflow(workflow);
    }
  };

  const removeTriggerMapRow = (rowArray: any) => {
    const row = {
      definition: rowArray[0].definition,
      field: rowArray[1].field,
      sample: rowArray[2].sample,
      target: rowArray[3].target,
    };
    delete workflow.trigger.custom[row.field];
    setWorkflow(workflow);
    hydrateMappingGrids();
  };

  const setCsvFileHandler = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const csv = reader.result as string;
      const lines = csv.split('\n');
      const headers = lines[0].split(',');
      const data: any[] = [];
      for (let i = 0; i < lines.length; i++) {
        let obj: any = {};
        const currentline = lines[i].split(',');
        for (let j = 0; j < headers.length; j++) {
          obj = {
            id: i,
            key: currentline[0],
            value: currentline[1].replace(/[\n\r]+/g, ''),
          };
        }
        data.push(obj);
      }
      if (workflow.lookups.length > 0) {
        for (let index = 0; index < workflow.lookups.length; index++) {
          const lookup = workflow.lookups[index];
          if (lookup.field === selectedField[1].field) {
            lookup.map = data;
          }
        }
      }
      setWorkflow(workflow);
    };
    reader.readAsText(file);
  };

  const clearToggle = (field: string) => {
    if (workflow.lookups.length > 0) {
      for (let index = 0; index < workflow.lookups.length; index++) {
        if (workflow.lookups[index].field === field) {
          workflow.lookups[index] = {
            field,
            map: [{ id: 1, key: '', value: '' }],
            default: '',
          };
        }
      }
    }
    setWorkflow(workflow);
    setLookUpModal(false);
  };

  const newWorkflowClickHandler = () => {
    setWorkflow(placeholder);
    setUx('adapters');
  };

  const backToListClickHandler = () => {
    if (isStarted()) setShowConfirmModal(true);
    else setUx('list');
  };

  const eventTriggerClickHandler = () => {
    setUx('e-layer');
  };

  const adapterClickHandler = () => {
    setUx('adapters');
  };

  const mapClickHandler = async () => {
    await hydrateMappingGrids();
    setUx('t-layer');
  };

  const loadClickHandler = async () => {
    if (workflow.next.type === 'HTTPS JSON' || workflow.next.type.substring(0, 4) === 'MLLP') await validatePayload();
    setUx('l-layer');
  };

  const enableSampleFilter = async () => {
    setSampleFilter(!sampleFilter);
    hydrateMappingGrids();
  };

  const updateEventTrigger = (event: any) => {
    const eventTrigger = event;
    workflow.trigger = { ...workflow.trigger, ...eventTrigger };
    setWorkflow(workflow);
    setTrigger(trigger);
  };

  const updateActionTrigger = (event: any) => {
    const eventNext = event;
    workflow.next = { ...workflow.next, ...eventNext };
    if (workflow.next.type === 'S3 FHIR R4' || workflow.next.type === 'HTTPS FHIR R4') {
      workflow.icon = 'FireIcon';
    }
    if (workflow.next.type === 'S3 JSON' || workflow.next.type === 'HTTPS JSON') {
      workflow.icon = 'BoltIcon';
    }
    if (workflow.next.type.substring(0, 4) === 'MLLP') {
      workflow.icon = 'CommandLineIcon';
    }
    setWorkflow(workflow);
    setNext(next);
  };

  const fetchHL7v2Map = async () => {
    const body = { selectedAction: workflow.next.action };
    const segmentRequest = await post(`workflow/list-segments/json-hl7v2`, body);
    return segmentRequest.segments;
  };

  const fetchFHIRR4Map = async () => {
    setFhirLoading(true);
    props.notify({ message: '✨ Generating FHIR R4 Semantic Map...' });

    const sampleMessageLines = workflow.sampleMessage.split(/\r?\n/);
    const fhirMapsCombined: Record<string, any> = {};
    const promises: Promise<void>[] = [];

    for (let i = 0; i < sampleMessageLines.length; i++) {
      const line = sampleMessageLines[i];
      if (line.length <= 1) {
        continue; // Skip lines with length <= 1
      }

      const body = { sampleMessageSegment: line };
      const fhirUrl = `workflow/hl7v2-to-fhir/${props.user.sub}`;

      promises.push(
        post(fhirUrl, body)
          .then((rst: any) => {
            const reqObj = Object.entries(rst);
            if (rst && reqObj[1]?.length > 0) {
              Object.assign(fhirMapsCombined, rst);
            }
          })
          .catch((err: any) => {
            console.error(`Error posting line:`, err);
          }),
      );
    }

    await Promise.all(promises);

    setFhirLoading(false);

    if (Object.keys(fhirMapsCombined).length > 0) {
      setFhirMap(fhirMapsCombined);
      workflow.fields = fhirMapsCombined;
      setWorkflow(workflow);
      hydrateMappingGrids();

      const payload = createFHIRBundle(fhirMapsCombined, workflow.sampleMessage);
      setFHIRSampleMap(payload);

      props.notify({ message: 'Generated FHIR R4 Semantic Map' });
    }
  };

  const getHL7SegmentFields = async (segments: any) => {
    const body = { segments };
    const segmentRequest = await post(`workflow/list-sample-segment-fields/${props.user.sub}`, body);
    return segmentRequest.segmentRequest;
  };

  const updateHL7ActionTrigger = async (event: any) => {
    const eventNext = event;
    workflow.next.action = eventNext.label;
    const segmentLists = await fetchHL7v2Map();
    const fields = await getHL7SegmentFields(segmentLists);
    setSegmentFields(fields);
    parseJSONSampleIntoGrid();
  };

  const parseJSONSampleIntoGrid = async () => {
    const fieldMapGrid: any = [];
    props.notify({ message: 'Parsing Sample Message...' });
    const segFields = segmentFields || (await getHL7SegmentFields(workflow.next.action));
    segFields.map((row: any) => {
      if (Object.entries(workflow.fields).length > 0) {
        Object.entries(workflow.fields).map((field: any) => {
          const [fieldKey, fieldValue] = field;
          if (row[0] === fieldKey && fieldValue) {
            fieldMapGrid.push([
              { definition: row[1] ? row[1] : 'CUSTOM' },
              { field: row[0] },
              { sample: fieldValue.value },
              { target: fieldValue.key ? fieldValue.key : '' },
            ]);
          }
        });
      }
      const duplicateFields = fieldMapGrid.filter((gridRow: any) => gridRow[1].field == row[0]);
      if (duplicateFields.length === 0 && sampleFilter) {
        fieldMapGrid.push([{ definition: row[1] ? row[1] : 'CUSTOM' }, { field: row[0] }, { sample: '' }, { target: '' }]);
      }
    });
    setWorkflow(workflow);
    setFieldMapGrid(fieldMapGrid);
  };

  const parseJSONSampleMessage = (message: any) => {
    const sample = Object.entries(message);
    const targets: any = [];
    sample.map((sampleRow: any) => {
      const isPatient = sampleRow[0] == 'patient';
      if (isPatient) {
        const patient = Object.entries(sampleRow[1]);
        patient.map((patientRow: any) => {
          if (typeof patientRow[1] === 'string') {
            targets.push({
              key: patientRow[0],
              value: patientRow[1],
              label: patientRow[0],
            });
          }
          if (typeof patientRow[1] === 'object' && typeof patientRow[1][0] !== 'object') {
            targets.push({
              key: patientRow[0],
              value: patientRow[1][0],
              label: patientRow[0],
            });
          }
          if (typeof patientRow[1] === 'object' && typeof patientRow[1][0] === 'object') {
            const position = Object.entries(patientRow[1][0])[0][1];
            targets.push({
              key: patientRow[0],
              value: Object.entries(patientRow[1][0])[1][1],
              label: patientRow[0],
            });
          }
        });
      }
    });
    setTargets(targets);
  };

  const setCopiedHandler = () => {
    setCopied(true);
  };

  const clearError = () => {
    setError('');
  };

  const showEnvironmentDropdownHandler = () => {
    setShowEnvironmentDropdown(!showEnvironmentDropdown);
  };

  const showSampleMessageInput = () => {
    setSampleMessageInput(!sampleMessageInput);
    setTriggerMap(!triggerMap);
  };

  const updateTriggerMethod = async (event: any) => {
    const method = event.method;
    workflow.next.method = method;
    setWorkflow(workflow);
  };

  const onChangeHost = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updated = {
      ...workflow,
      trigger: { ...workflow.trigger, host: event.target.value },
    };
    setWorkflow(updated);
  };

  const onChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updated = {
      ...workflow,
      trigger: { ...workflow.trigger, username: event.target.value },
    };
    setWorkflow(updated);
  };

  const onChangePort = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updated = {
      ...workflow,
      trigger: { ...workflow.trigger, port: event.target.value },
    };
    setWorkflow(updated);
  };

  const onChangePath = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updated = {
      ...workflow,
      trigger: { ...workflow.trigger, path: event.target.value },
    };
    setWorkflow(updated);
  };

  const onChangeCadence = (option: any) => {
    const updated = {
      ...workflow,
      cadence: option,
    };
    setWorkflow(updated);
  };

  const onChangeCert = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updated = {
      ...workflow,
      trigger: { ...workflow.trigger, cert: event.target.value },
    };
    setWorkflow(updated);
  };

  const onChangePrivateKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updated = {
      ...workflow,
      trigger: { ...workflow.trigger, privateKey: event.target.value },
    };
    setWorkflow(updated);
  };

  const showTemplates = () => {
    setShowTemplatesModal(!showTemplatesModal);
  };

  const updateIcon = (icon: string) => {
    setWorkflow({ ...workflow, icon: icon });
  };

  const startFromTemplate = async (template: any) => {
    placeholder.flowName = template.name;
    placeholder.sampleMessage = '';
    placeholder.fields = template.fields;
    placeholder.next = template.next;
    placeholder.trigger = template.trigger;
    placeholder.lookups = template.lookups;
    loadWorkflow(placeholder);
  };

  const toggleActive = () => {
    const flow = workflow;
    flow.active = workflow?.active ? false : true;
    setWorkflow(flow);
  };

  useEffect(() => {
    if (!definitions.length) {
      bootstrapDefinitions();
    }
    fetchTemplates();
    getTunnels();
  }, [bootstrapDefinitions, fetchTemplates, getTunnels, props.selectedEnvironment]);

  useEffect(() => {
    if (workflow.fields && workflow.sampleMessage) {
      validatePayload();
    }
  }, [workflow.sampleMessage, validatePayload]);

  useEffect(() => {
    if (workflow.fields && workflow.sampleMessage) {
      hydrateMappingGrids();
      validatePayload();
    }
  }, [workflow.fields]);

  useEffect(() => {
    if (workflow.trigger.type.substring(0, 4) === 'MLLP' || workflow.next.type.substring(0, 4) === 'MLLP') {
      hydrateMappingGrids();
    }
  }, [sampleFilter]);

  return {
    ux,
    setUx,
    error,
    setError,
    copied,
    setCopied,
    pending,
    setPending,
    lookUpModal,
    setLookUpModal,
    segmentFields,
    setSegmentFields,
    selectedField,
    setSelectedField,
    selectedFieldMap,
    setSelectedFieldMap,
    selectedDefaultValue,
    setSelectedDefaultValue,
    triggerSample,
    setTriggerSample,
    triggerMap,
    setTriggerMap,
    showEnvironmentDropdown,
    setShowEnvironmentDropdown,
    trigger,
    setTrigger,
    next,
    setNext,
    sampleMap,
    setSampleMap,
    sampleMessageInput,
    setSampleMessageInput,
    targets,
    setTargets,
    fieldMapGrid,
    setFieldMapGrid,
    eventTriggerGrid,
    setEventTriggerGrid,
    sampleFilter,
    setSampleFilter,
    definitions,
    setDefinitions,
    workflow,
    setWorkflow,
    currentLookups,
    setCurrentLookups,
    showConfirmModal,
    setShowConfirmModal,
    showMessageIDModal,
    setShowMessageIDModal,
    messageEvent,
    fhirMap,
    setFhirMap,
    fhirLoading,
    setFhirLoading,
    fhirSampleMap,
    setFHIRSampleMap,
    showTemplatesModal,
    setShowTemplatesModal,
    templates,
    setTemplates,
    tunnels,
    setTunnels,
    loading,
    bootstrapDefinitions,
    fetchTemplates,
    getTunnels,
    hydrateMappingGrids,
    updateFieldMap,
    canCreate,
    canUpdate,
    clearWorkflow,
    loadWorkflow,
    saveWorkflow,
    saveTemplate,
    saveFlow,
    isStarted,
    handleFlowNameChange,
    handleFlowDescriptionChange,
    handleSampleMessageChange,
    handleNextS3BucketName,
    handleNextS3Key,
    handleNextTunnel,
    createS3Bucket,
    handleNextUrl,
    handleNextIP,
    handleNextPort,
    validatePayload,
    updateFieldMapKey,
    updateFieldMapValue,
    handleLookupDefaultValueChange,
    toggleLookUpModal,
    addRowToLookupsHandler,
    removeRow,
    removeFieldMapRow,
    updateTrigger,
    removeTriggerMapRow,
    setCsvFileHandler,
    clearToggle,
    newWorkflowClickHandler,
    backToListClickHandler,
    eventTriggerClickHandler,
    adapterClickHandler,
    mapClickHandler,
    loadClickHandler,
    enableSampleFilter,
    updateEventTrigger,
    updateActionTrigger,
    fetchHL7v2Map,
    fetchFHIRR4Map,
    getHL7SegmentFields,
    updateHL7ActionTrigger,
    parseJSONSampleIntoGrid,
    parseJSONSampleMessage,
    setCopiedHandler,
    clearError,
    showEnvironmentDropdownHandler,
    showSampleMessageInput,
    updateTriggerMethod,
    onChangeHost,
    onChangeUsername,
    onChangePort,
    onChangePath,
    onChangeCadence,
    onChangeCert,
    onChangePrivateKey,
    showTemplates,
    updateIcon,
    startFromTemplate,
    placeholder,
    hl7v2EventTofhirr4MethodMap,
    toggleActive,
  };
};

export default useWorkflows;
