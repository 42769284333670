import React from 'react';
import { XYPlot, VerticalGridLines, HorizontalGridLines, XAxis, YAxis, AreaSeries, makeVisFlexible } from 'react-vis';

const FlexibleXYPlot = makeVisFlexible(XYPlot);

const style = { strokeWidth: 2 };
const opacity = 0.9;

export default function AreaChart({ workflows }: any) {
  return (
    <FlexibleXYPlot xType="ordinal" stackBy="y">
      <VerticalGridLines />
      <HorizontalGridLines />
      <XAxis
        style={{
          text: { fill: '#d3d3d3', fontSize: '10px' },
        }}
      />
      <YAxis
        tickFormat={(tickValue) => {
          const roundedTickValue = tickValue === Math.ceil(tickValue) ? tickValue : '';
          return roundedTickValue.toLocaleString();
        }}
        style={{
          text: { fill: '#d3d3d3', fontSize: '10px' },
        }}
      />
      {workflows.map((workflow: any) => (
        <AreaSeries
          key={`${workflow.SK}-successes`}
          stroke="#22d3ee"
          fill="#06b6d4"
          opacity={opacity}
          style={style}
          data={workflow.gridSuccesses ? workflow.gridSuccesses.data : [{}]}
        />
      ))}
      {workflows.map((workflow: any) => (
        <AreaSeries
          key={`${workflow.SK}-warnings`}
          stroke="#fdba74"
          fill="#fb923c"
          opacity={opacity}
          style={style}
          data={workflow.gridErrors ? workflow.gridErrors.data : [{}]}
        />
      ))}
    </FlexibleXYPlot>
  );
}
