/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/function-component-definition */
import { useState, useEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { TrashIcon, InformationCircleIcon, Cog6ToothIcon, CheckIcon } from '@heroicons/react/24/outline';
import SaveInAnotherAccountAlert from '../../Base/Elements/Alerts/SaveInAnotherAccountAlert';
import UserPermissionsAlert from '../../Base/Elements/Alerts/UserPermissionsAlert';
import MessageIdentifierInfoAlert from '../../Base/Elements/Alerts/MessageIdentifierInfoAlert';
import Toggle from './Elements/GridToggle';
import Dropdown from './Elements/WorkflowDropDown';
import SFTPConfigModal from './Elements/SFTPConfigModal';

export default function ELayer(props: any) {
  const selectedEnvironment = props.selectedEnvironment ? props.selectedEnvironment : null;
  const mllpURL =
    selectedEnvironment.accountId !== '587677186137' ? `${selectedEnvironment?.network.nlb?.DNSName.toLowerCase()}:2575` : 'mllp.retrohook.com:2575';
  const httpURL =
    selectedEnvironment.accountId !== '587677186137' ? `${selectedEnvironment?.network.webhook?.ApiEndpoint}/messages/${props.workflow.SK.split('#')[1]}` : '';
  const clipBoardURL = props.workflow.trigger.type.substring(0, 4) == 'HTTP' ? httpURL : mllpURL;
  const [eventTriggerGrid, setEventTriggerGrid] = useState(props.eventTriggerGrid);
  const [showMessageIdInfo, setShowMessageIdInfo] = useState(false);
  const [SFTPTriggerModal, setSFTPTriggerModal] = useState(false);
  const triggerShort = props?.workflow.trigger.type.substring(0, 4);
  const actionShort = props?.workflow.next.type.substring(0, 4);
  const closeModal = () => {
    setSFTPTriggerModal(false);
  };

  useEffect(() => {
    setEventTriggerGrid(props.eventTriggerGrid);
  }, [props.eventTriggerGrid]);

  return (
    <div className="py-4">
      {props.error == 'SaveInAnotherAccountException' && <SaveInAnotherAccountAlert closeAlert={() => props.clearError()} />}
      {props.error == 'UserPermissionsException' && <UserPermissionsAlert closeAlert={() => props.clearError()} />}
      {showMessageIdInfo && <MessageIdentifierInfoAlert closeAlert={() => setShowMessageIdInfo(false)} />}
      {SFTPTriggerModal && (
        <SFTPConfigModal
          isOpen={SFTPTriggerModal}
          onChangeHost={props.onChangeHost}
          onChangeUsername={props.onChangeUsername}
          onChangePassword={props.onChangePassword}
          onChangePort={props.onChangePort}
          onChangePath={props.onChangePath}
          onChangeCadence={props.onChangeCadence}
          workflow={props.workflow}
          closeModal={closeModal}
          onChangeCert={props.onChangeCert}
          onChangeKey={props.onChangeKey}
          onChangePrivateKey={props.onChangePrivateKey}
        />
      )}
      <div className="inline">
        <div className="md:mt-0">
          <div className="sm:rounded-md sm:overflow-hidden">
            <div className="dark:bg-gray-800 rounded-sm space-y-4 px-2 pt-6 mx-2 pb-6 border-solid border-2 shadow border-cyan-400">
              {triggerShort == 'MLLP' && (
                <div className="py-2">
                  <div className="relative border-2 border-cyan-400 rounded-sm px-2 py-2 w-full">
                    <label
                      htmlFor="env-info"
                      className="absolute rounded -top-4 left-2 -mt-px inline-block px-1 bg-gray-100 animate-color-change-2x dark:animate-color-change-2x-dark text-md font-medium text-gray-600 dark:text-gray-200"
                    >
                      MLLP Connection Address (HL7 Outbound Interface)
                    </label>
                    <div className="mt-1 flex">
                      <p id="env-info" className=" text-xl dark:text-cyan-400 text-gray-600 flex-1 inline w-full">
                        {selectedEnvironment.accountId === '587677186137' ? (
                          <span className="text-sm p-4 dark:text-cyan-400 text-gray-600">
                            mllp.retrohook.com:2575 <span className="text-sm text-red-400">MLLP Only </span>
                            <span className="text-red-400"> (IPSec required)</span>.{' '}
                          </span>
                        ) : (
                          ``
                        )}
                        {selectedEnvironment.network && selectedEnvironment.accountId === '411790770360' ? (
                          <span className="text-sm p-4 dark:text-cyan-400 text-gray-600">
                            {selectedEnvironment.network.nlb.DNSName.toLowerCase()}:2575
                            <span className="text-sm text-red-400">&nbsp;MLLP Only </span>
                            <span className="text-red-400"> (No PII Allowed)</span>.{' '}
                          </span>
                        ) : (
                          ``
                        )}
                        {selectedEnvironment.network && selectedEnvironment.accountId !== '411790770360' && selectedEnvironment.accountId !== '587677186137' ? (
                          <span className="text-sm p-4 dark:text-cyan-400 text-gray-600">
                            {selectedEnvironment.network?.nlb?.DNSName.toLowerCase()} :2575
                            <span className="text-sm text-gray-600">&nbsp;MLLP Only </span>
                            <span className="text-pink-400"> (IPSec required)</span>.{' '}
                          </span>
                        ) : (
                          ``
                        )}
                      </p>
                      <CopyToClipboard
                        text={
                          selectedEnvironment.accountId !== '587677186137'
                            ? `${selectedEnvironment.network?.nlb?.DNSName.toLowerCase()}:2575`
                            : 'mllp.retrohook.com:2575'
                        }
                        onCopy={() => props.setCopiedHandler()}
                      >
                        <button
                          type="button"
                          className="text-xs text-gray-600 dark:text-gray-200 border-cyan-500 hover:text-cyan-300 hover:border-cyan-300 border rounded px-2"
                        >
                          Copy To Clipboard {props.copied ? <CheckIcon className="h-4 w-4 pt-0 mt-0 dark:text-cyan-400 text-gray-600 inline" /> : null}
                        </button>
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
              )}
              {actionShort == 'MLLP' && (
                <div className="relative flex border border-gray-300 rounded px-2 py-2 my-4 shadow-sm">
                  <label
                    htmlFor="env-info"
                    className="absolute rounded -top-4 left-2 -mt-px inline-block px-1 bg-gray-100 dark:bg-gray-800 text-xs font-medium text-gray-600 dark:text-gray-200 z-10"
                  >
                    Webhook URL (HTTP POST)
                  </label>
                  <div className="mt-1 flex">
                    <p id="env-info" className="text-xl text-gray-200 flex-1 inline">
                      {selectedEnvironment.accountId !== '587677186137' ? (
                        <span className="text-sm p-4 dark:text-cyan-400 text-gray-600">
                          {selectedEnvironment?.network?.webhook?.ApiEndpoint}/messages/{props.workflow.SK.split('#')[1]}{' '}
                          <span className="text-sm text-gray-600" />
                        </span>
                      ) : (
                        ``
                      )}
                    </p>
                    <CopyToClipboard text={clipBoardURL} onCopy={() => props.setCopiedHandler()}>
                      <button
                        type="button"
                        className="text-xs text-gray-600 dark:text-gray-200 border-gray-500 hover:text-cyan-300 hover:border-cyan-300 border rounded px-2"
                      >
                        Copy To Clipboard {props.copied ? <CheckIcon className="h-4 w-4 pt-0 mt-0 text-cyan-300 inline" /> : null}
                      </button>
                    </CopyToClipboard>
                  </div>
                </div>
              )}
              {(props.workflow.trigger.type.substring(0, 4) == 'MLLP' || props.workflow.trigger.type.substring(0, 4) == 'SFTP') && (
                <div>
                  <table className="min-w-full divide-y divide-cyan-400 border-2 border-cyan-400 shadow">
                    <thead className="dark:bg-gray-900">
                      <tr>
                        <td colSpan={4} className="">
                          <div className="flex justify-between items-center px-4 py-2">
                            <h2 className="text-md text-gray-600 dark:text-gray-200">Event Filter</h2>
                            <div className="flex items-center space-x-4">
                              {(props.workflow.trigger.type.substring(0, 4) === 'MLLP' || props.workflow.trigger.type.substring(0, 4) === 'SFTP') &&
                                props.workflow.next.type === 'HTTPS FHIR R4' && (
                                  <Dropdown
                                    selectHandler={(event: any) => props.updateTriggerMethod(event)}
                                    selected={props.workflow?.next?.method}
                                    Types={props.hl7v2EventTofhirr4MethodMap}
                                  />
                                )}
                              {props.workflow.trigger.type.substring(0, 4) === 'SFTP' && (
                                <div
                                  onClick={() => {
                                    setSFTPTriggerModal(!SFTPTriggerModal);
                                  }}
                                  className="flex items-center border-2 rounded-md px-2 border-gray-200 cursor-pointer hover:bg-cyan-500"
                                >
                                  <Cog6ToothIcon className="h-5 w-5 dark:text-gray-200 text-gray-600" />
                                  <span className="text-sm dark:text-gray-200 text-gray-600 px-2 py-1">SFTP</span>
                                </div>
                              )}
                              {props.workflow.trigger.type.split(' ')[1] === 'HL7v2' && (
                                <div className="flex items-center space-x-2 py-1 text-sm text-gray-600 dark:text-gray-100 dark:bg-gray-800">
                                  <Toggle enabled={props.sampleFilter} setEnabled={() => props.enableSampleFilter()} />
                                  <span>View All</span>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="border-t border-t-cyan-400" />
                        </td>
                      </tr>
                      <tr>
                        <th scope="col" className="pl-4 pr-2 py-2 text-left text-sm font-medium text-cyan-300 uppercase tracking-wider">
                          Field
                        </th>
                        <th scope="col" className="px-2 py-2 text-left text-sm font-medium text-cyan-300 uppercase tracking-wider">
                          Specification
                        </th>
                        <th scope="col" className="px-2 py-2 text-left text-sm font-medium text-cyan-300 tracking-wider">
                          SAMPLE
                        </th>
                        <th scope="col" className="px-2 py-2 text-left text-sm font-medium text-cyan-300 tracking-wider">
                          FILTER
                          <InformationCircleIcon
                            className="h-5 w-5 pb-1 inline align-middle text-pink-300 rounded cursor-pointer hover:text-pink-500"
                            onClick={() => {
                              setShowMessageIdInfo(true);
                            }}
                            closeAlert={() => {
                              setShowMessageIdInfo(false);
                            }}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedEnvironment.accountId != '411790770360' && props.triggerSample == props.selectedWorkspace.hash.value && (
                        <tr key="unique-message-id" className="dark:bg-gray-800 border-2 border-solid border-green-400 hover:bg-cyan-500">
                          <td className="pl-4 pr-2 py-2 whitespace-nowrap text-sm dark:text-gray-200 text-gray-600">{props.selectedWorkspace.hash.location}</td>
                          <td className="px-2 py-2 whitespace-wrap text-xs font-light dark:text-gray-200 text-gray-600">Unique Message Identifier</td>
                          <td className="px-2 py-2 whitespace-wrap text-xs dark:text-gray-`00 text-gray-600">{props.triggerSample}</td>
                          <td className="px-2 py-2 whitespace-wrap text-xs dark:text-gray-200 text-gray-600">{props.selectedWorkspace.hash.value}</td>
                        </tr>
                      )}
                      {selectedEnvironment.accountId != '411790770360' && props.triggerSample != props.selectedWorkspace.hash.value && (
                        <tr key="unique-message-id" className="dark:bg-gray-800 border-2 border-solid border-pink-400 hover:bg-pink-500">
                          <td className="pl-4 pr-2 py-2 whitespace-nowrap text-sm dark:text-gray-200 text-gray-600">{props.selectedWorkspace.hash.location}</td>
                          <td className="px-2 py-2 whitespace-wrap text-xs font-light dark:text-gray-200 text-gray-600">Unique Message Identifier</td>
                          <td className="px-2 py-2 whitespace-wrap text-xs dark:text-gray-`00 text-gray-600">{props.triggerSample}</td>
                          <td className="px-2 py-2 whitespace-wrap text-xs dark:text-gray-200 text-gray-600">{props.selectedWorkspace.hash.value}</td>
                        </tr>
                      )}
                      {eventTriggerGrid.map((map: any, idx: number) => (
                        <tr
                          key={`${map[1].field}-${map[2].sample}${idx}`}
                          className={idx % 2 === 0 ? 'bg-gray-100 dark:bg-gray-700 hover:bg-cyan-500' : 'dark:bg-gray-800 hover:bg-cyan-500'}
                        >
                          <td className="pl-4 pr-2 py-2 whitespace-nowrap text-sm dark:text-gray-200 text-gray-600">{map[1].field}</td>
                          <td className="px-2 py-2 whitespace-wrap text-xs font-light dark:text-gray-200 text-gray-600">{map[0].definition}</td>
                          <td className="px-2 py-2 whitespace-wrap text-sm dark:text-gray-`00 text-gray-600">{map[2].sample}</td>
                          <td className="px-2 py-2 whitespace-wrap flex text-sm dark:text-gray-`00 text-gray-600">
                            <input
                              type="text"
                              name="filter"
                              id={map[1].field}
                              defaultValue={map[3].filter}
                              onChange={(event) => props.updateTrigger(event)}
                              className="shadow-sm flex focus:ring-cyan-300 focus:border-cyan-300 block w-full sm:text-sm border-cyan-300 border-2 rounded dark:bg-gray-800"
                            />
                            {!props.sampleFilter && (
                              <div
                                className="pl-2 pt-1 inline rounded-md"
                                onClick={() => {
                                  props.removeTriggerMapRow(map);
                                }}
                              >
                                <TrashIcon className="h-8 w-8 inline cursor-pointer text-gray-400 hover:text-pink-400" />
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
