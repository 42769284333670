/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable eqeqeq */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/function-component-definition */

import { InboxArrowDownIcon, MapIcon, ShareIcon, RectangleStackIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import InnerNavButton from '../../../Base/Layout/NavigationElements/InnerNavButton';
import InnerNavButtonSelected from '../../../Base/Layout/NavigationElements/InnerNavButtonSelected';
import DropdownButton from './SaveDropdown';
import ActionButton from '../../../Base/Layout/NavigationElements/ActionButton';

export default function SubNav(props: any) {
  const saveOptions = [
    {
      label: props.template?.PK ? 'Update Template' : 'Generate Template',
      action: props.saveTemplate,
    },
    {
      label: props.workflow?.PK ? 'Update Workflow' : 'Create Workflow',
      action: props.saveWorkflow,
    },
  ];
  const createOptions = [
    {
      label: 'Generate Template',
      action: props.saveTemplate,
    },
    {
      label: 'Create Workflow',
      action: props.saveWorkflow,
    },
  ];

  return (
    <div className="dark:bg-gray-800 bg-cyan-400 shadow-sm min-w-0 flex px-8 py-1 h-10">
      <div className="w-full flex justify-end">
        {props.ux == 'adapters' && <InnerNavButtonSelected title="General" icon={RectangleStackIcon} clickHandler={() => props.adapterClickHandler()} />}
        {props.ux != 'adapters' && <InnerNavButton title="General" icon={RectangleStackIcon} clickHandler={() => props.adapterClickHandler()} />}
        {props.ux == 'e-layer' && props.workflow.trigger.type.substring(0, 4) != 'HTTP' && (
          <InnerNavButtonSelected title="Events" icon={InboxArrowDownIcon} clickHandler={() => props.eventTriggerClickHandler()} />
        )}
        {props.ux != 'e-layer' && props.workflow.trigger.type.substring(0, 4) != 'HTTP' && (
          <InnerNavButton title="Events" icon={InboxArrowDownIcon} clickHandler={() => props.eventTriggerClickHandler()} />
        )}
        {props.ux == 't-layer' && <InnerNavButtonSelected title="Data" icon={MapIcon} clickHandler={() => props.mapClickHandler()} />}
        {props.ux != 't-layer' && <InnerNavButton title="Data" icon={MapIcon} clickHandler={() => props.mapClickHandler()} />}
        {props.ux == 'l-layer' && <InnerNavButtonSelected title="Action" icon={ShareIcon} clickHandler={() => props.loadClickHandler()} />}
        {props.ux != 'l-layer' && <InnerNavButton title="Action" icon={ShareIcon} clickHandler={() => props.loadClickHandler()} />}
      </div>
      {props.workflow?.sampleMessage && !props.workflow?.PK && <DropdownButton label="Create" options={createOptions} />}
      {props.workflow?.PK && <DropdownButton label="Save" options={saveOptions} />}
      <div className="border-l-2 border-l-gray-400 dark:border-l-gray-700 pr-2" />
      <ActionButton
        title="Close"
        icon={ArrowLeftIcon}
        clickHandler={() => {
          props.backToListClickHandler();
        }}
      />
    </div>
  );
}
