/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-undef */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Auth0Provider } from '@auth0/auth0-react';
import { AuthProvider } from './app/context/AuthContext';
import { ThemeProvider } from './app/context/ThemeContext';
import App from './App';
import config from './config';

Sentry.init({
  dsn: 'https://4305ed2aba024cc4905ae5def53f897e@o1350334.ingest.us.sentry.io/6630613',
  environment: config.ENV,
  tracesSampleRate: 0.1,
});

const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={config.domain as string}
      clientId={config.clientId as string}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: config.ENV == 'production' ? 'https://api.retrohook.com' : 'https://stage.api.retrohook.com',
        scope: config.scope as string,
      }}
    >
      <BrowserRouter>
        <ThemeProvider>
          <AuthProvider>
            <App />
          </AuthProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Auth0Provider>
  </React.StrictMode>,
);
