/* eslint-disable react/function-component-definition */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { ArrowUpOnSquareIcon } from '@heroicons/react/24/outline';
import { IconPickerItem } from 'react-heroicons-picker';
import { ArrowLongRightIcon } from '@heroicons/react/24/solid';
import SaveInAnotherAccountAlert from '../../Base/Elements/Alerts/SaveInAnotherAccountAlert';
import UserPermissionsAlert from '../../Base/Elements/Alerts/UserPermissionsAlert';
import WorkflowNameInputBox from './Elements/WorkflowNameInputBox';
import FlowHeading from './Elements/FlowHeading';
import Dropdown from './Elements/WorkflowDropDown';

const defaultEventTypes = [
  {
    id: 1,
    type: 'MLLP HL7v2',
    label: 'HL7v2 Message Event (TCP/MLLP)',
    description: 'HL7v2 Messages over MLLP',
    disabled: false,
  },
  {
    id: 2,
    type: 'HTTPS JSON',
    label: 'JSON Request (HTTPS)',
    description: 'Consume JSON over HTTP POST requests',
    disabled: false,
  },
  {
    id: 3,
    type: 'HTTPS FHIR R4',
    label: 'FHIR R4 Bundle Request (HTTPS)',
    description: 'Consume FHIR Over HTTP Post requests',
    disabled: true,
  },
  {
    id: 4,
    type: 'HTTPS HL7v2',
    label: 'HL7v2 Message Request (HTTPS)',
    description: 'Consume HL7v2 over HTTP POST requests',
    disabled: true,
  },
  {
    id: 5,
    type: 'HTTPS C-CDA',
    label: 'C-CDA XML Request (HTTPS)',
    description: 'Consume C-CDA From HTTP',
    disabled: false,
  },
  {
    id: 6,
    type: 'SFTP HL7v2',
    label: 'HL7v2 Message Polling (SFTP)',
    description: 'HL7v2 Messages over SFTP',
    disabled: true,
  },
  {
    id: 7,
    type: 'SOAP C-CDA',
    label: 'C-CDA XML Request (SOAP)',
    description: 'Consume C-CDA From SOAP',
    disabled: true,
  },
];
const defaultActionTypes = [
  {
    id: 1,
    type: 'HTTPS JSON',
    label: 'JSON POST Request (HTTPS)',
    description: 'POST JSON Objects over HTTPS',
    disabled: false,
  },
  {
    id: 2,
    type: 'S3 JSON',
    label: 'JSON Write (S3)',
    description: 'Write JSON Objects to AWS S3',
    disabled: false,
  },
  {
    id: 3,
    type: 'S3 FHIR R4',
    label: 'FHIR R4 Bundle Write (S3)',
    description: 'Write FHIR Bundles to AWS S3',
    disabled: false,
  },
  {
    id: 4,
    type: 'MLLP HL7v2',
    label: 'HL7v2 Message Publish (TCP/MLLP)',
    description: 'Send MLLP Events To An IP & Port',
    disabled: false,
  },
  {
    id: 5,
    type: 'HTTPS FHIR R4',
    label: 'FHIR R4 Bundle POST (HTTPS)',
    description: 'HTTPS POST FHIR Bundle Transactions',
    disabled: true,
  },
  {
    id: 6,
    type: 'HTTPS C-CDA XML',
    label: 'C-CDA XML POST (HTTPS)',
    description: 'POST C-CDA XML Over HTTPS',
    disabled: false,
  },
  {
    id: 7,
    type: 'SOAP C-CDA XML',
    label: 'C-CDA XML POST (SOAP)',
    description: 'POST C-CDA XML Over SOAP',
    disabled: true,
  },
  // {
  //   id: 8,
  //   type: 'Pipedream JSON',
  //   label: 'Pipedream JSON API (OAUTH)',
  //   description: 'Send JSON Events To Pipedream',
  //   disabled: false,
  // },
];

export default function Adapters(props: any) {
  const [eventTypes, setEventTypes] = useState(defaultEventTypes);
  const [actionTypes, setActionTypes] = useState(defaultActionTypes);
  const [selectedEnvironment, setSelectedEnvironment] = useState(props.selectedEnvironment);
  const [activeFlow, setActiveFlow] = useState(props?.activeFlow);

  useEffect(() => {
    setSelectedEnvironment(props.selectedEnvironment);
  }, [props.selectedEnvironment]);

  useEffect(() => {
    // const updateDropdowns = () => {
    //   const isDevEnvironment = selectedEnvironment.name.substring(3, 6) === 'Dev';
    //   const updatedEventTypes = defaultEventTypes.map((eventType) => ({
    //     ...eventType,
    //     disabled: isDevEnvironment && eventType.type.startsWith('HTTP') ? true : eventType.disabled,
    //   }));

    //   const updatedActionTypes = defaultActionTypes.map((actionType) => ({
    //     ...actionType,
    //     disabled: isDevEnvironment && (actionType.type === 'MLLP HL7v2' || actionType.type.startsWith('S3')) ? true : actionType.disabled,
    //   }));

    //   setEventTypes(updatedEventTypes);
    //   setActionTypes(updatedActionTypes);
    // };

    // updateDropdowns();

    // return () => {
      setEventTypes(defaultEventTypes);
      setActionTypes(defaultActionTypes);
    // };
  }, [selectedEnvironment]);

  return (
    <div className="py-4">
      {props.error === 'SaveInAnotherAccountException' && <SaveInAnotherAccountAlert closeAlert={() => props.clearError()} />}
      {props.error === 'UserPermissionsException' && <UserPermissionsAlert closeAlert={() => props.clearError()} />}
      <div className="inline">
        <div className="px-2 md:mt-0">
          <div className="sm:rounded-md sm:overflow-hidden">
            <div className="animate-color-change-2x dark:animate-color-change-2x-dark rounded h-full space-y-2 p-10 mx-4 border-solid border shadow border-cyan-300">
              <div className="flex mb-4">
                <div className="inline-flex z-15 w-full">
                  <div className="z-20">
                    <div className="border-2 rounded-md text-cyan-500 px-2 py-1.5 mr-1 border-gray-500 hover:border-cyan-400 cursor-pointer hover:text-cyan-300">
                      <IconPickerItem icon={props.workflow?.icon ? props.workflow?.icon : 'BoltIcon'} library="outline" />
                    </div>
                  </div>
                  <div className="pr-2 w-full">
                    <WorkflowNameInputBox
                      name="Name"
                      onChange={props.handleFlowNameChange}
                      value={props.workflow?.flowName}
                      placeholder={props.placeholder.flowName}
                    />
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() => {
                    props.showTemplates();
                  }}
                  className=" text-gray-600 dark:text-gray-200 items-center px-2 py-1 mx-1 border text-xs rounded dark:bg-gray-700 border-pink-400 hover:border-pink-300 hover:bg-pink-400 hover:text-white focus:outline-none"
                >
                  Templates
                </button>
                {activeFlow ? (
                  <button
                    type="button"
                    onClick={() => {
                      props.toggleActive();
                      setActiveFlow(!activeFlow);
                    }}
                    className="text-gray-600 dark:text-gray-200 items-center px-2 py-1 mx-1 border text-xs rounded border-green-400 hover:border-purple-300 hover:text-white focus:outline-none"
                  >
                    Active
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => {
                      props.toggleActive();
                      setActiveFlow(!activeFlow);
                    }}
                    className="text-gray-600 dark:text-gray-200 items-center px-2 py-1 mx-1 border text-xs rounded border-purple-400 hover:border-green-400 hover:text-white focus:outline-none"
                  >
                    Inactive
                  </button>
                )}
              </div>
              <div className="items-center text-center border-2 border-purple-500 py-6 rounded mt-10">
                <p className="text-md text-gray-600 dark:text-gray-200 px-4">
                  Select the desired event type to initiate your workflow and the corresponding action you would like to take place as a result of the event.
                </p>
                <div className="flex flex-wrap justify-center space-x-4 pt-6">
                  <div className="min-w-96">
                    <h2 className="text-2xl font-medium text-gray-600 dark:text-gray-100 inline">Event</h2>
                    <Dropdown selectHandler={props.updateEventTrigger} selected={props.workflow?.trigger} Types={eventTypes} />
                  </div>
                  <ArrowLongRightIcon className="h-8 w-8 text-pink-500 dark:text-purple-500 mt-8" />
                  <div className="min-w-96">
                    <h2 className="text-2xl font-medium text-gray-600 dark:text-gray-100">Action</h2>
                    <Dropdown selectHandler={(event: any) => props.updateActionTrigger(event)} selected={props.workflow?.next} Types={actionTypes} />
                  </div>
                </div>
              </div>
              <div className="my-4">
                <div className="inline-flex w-full pb-2">
                  <FlowHeading icon={ArrowUpOnSquareIcon} title="Sample Message" />
                </div>
                <div className="relative border border-cyan-300 rounded px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-cyan-400 focus-within:border-cyan-400">
                  <div className="mt-1">
                    {(props.workflow.trigger.type.split(' ')[1] === 'HL7v2' || props.workflow.trigger.type === 'MLLP') ? (
                      <textarea
                        className="block text-xs w-full border-0 p-0 h-auto text-gray-500 bg-transparent dark:bg-gray-800 dark:text-cyan-400 placeholder-cyan-200 focus:ring-0"
                        id="sample-message-hl7"
                        name="sample-message"
                        rows={10}
                        defaultValue={props.workflow?.sampleMessage}
                        onChange={async (event) => props.handleSampleMessageChange(event)}
                      />
                    ) : null}
                    {props.workflow.trigger.type.split(' ')[1] === 'C-CDA' ? (
                      <textarea
                        className="block text-xs w-full border-0 p-0 h-auto text-gray-500 bg-transparent dark:bg-gray-800 dark:text-cyan-400 placeholder-cyan-200 focus:ring-0"
                        id="sample-message-ccda"
                        name="sample-message"
                        rows={10}
                        defaultValue={props.workflow?.sampleMessage}
                        onChange={async (event) => props.handleSampleMessageChange(event)}
                      />
                    ) : null}
                    {props.workflow.trigger.type.split(' ')[1] === 'JSON' ? (
                      <textarea
                        className="block text-xs w-full border-0 p-0 h-auto text-gray-500 bg-transparent dark:bg-gray-800 dark:text-cyan-400 placeholder-cyan-200 focus:ring-0"
                        id="sample-message-json"
                        name="sample-message"
                        rows={10}
                        defaultValue={props.workflow?.sampleMessage}
                        onChange={async (event) => props.handleSampleMessageChange(event)}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
