import React from 'react';

function DropdownButton(props: any) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className="relative inline-block text-left">
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="inline-flex justify-center w-full px-4 py-1 text-xs text-gray-200 bg-transparent focus:outline-none"
      >
        {props.label}
        <svg className="ml-2 -mr-1 mt-1 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      {isOpen && (
        <div className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div className="py-3" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {props.options.map((option: any, index: any) => (
              // eslint-disable-next-line react/button-has-type
              <button
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                onClick={() => {
                  option.action();
                  setIsOpen(false);
                }}
                className="block w-full text-left px-4 py-2 text-sm text-cyan-300 hover:text-white dark:text-gray-700 hover:bg-cyan-300"
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default DropdownButton;
